<template>
  <div class="custom-wrap gray-bcg-color">
    <div class="custom">
      <c-title :titleArr="customTitle" class="title"></c-title>
      <div class="content">
        <el-form :model="formData" ref="form" class="form" :rules="rules">
          <h3>基本信息</h3>
          <el-form-item prop="company">
            <el-input
              v-model.trim="formData.company"
              placeholder="公司名称"
            ></el-input>
            <i class="iconfont icon icongongsitubiao"></i>
          </el-form-item>
          <el-form-item prop="situation">
            <el-select
              placeholder="请选择应用场景"
              v-model.trim="formData.situation"
            >
              <el-option
                v-for="(item, index) in types"
                :key="index"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="question">
            <el-input
              v-model.trim="formData.question"
              type="textarea"
              placeholder="需求描述"
              rows="5"
            ></el-input>
          </el-form-item>
          <h3>联系人信息</h3>
          <el-form-item prop="name">
            <el-input
              v-model.trim="formData.name"
              placeholder="联系人姓名"
            ></el-input>
            <i class="iconfont icon iconxingmingtubiao"></i>
          </el-form-item>
          <el-form-item prop="connect">
            <el-input
              v-model.trim="formData.connect"
              placeholder="联系人电话"
            ></el-input>
            <i class="iconfont icon icondianhuatubiao"></i>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model.trim="formData.email"
              placeholder="联系人邮箱"
            ></el-input>
            <i class="iconfont icon iconyouxiang"></i>
          </el-form-item>
          <el-button
            :disabled="disabled"
            class="link-btn"
            type="primary"
            @click="submitApply"
            >立即申请</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { cTitle } from '@/components'
export default {
  components: { cTitle },
  data () {
    const validateEmail = (rule, value, callback) => {
      const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      if (!value) {
        return callback(new Error('联系人邮箱不能为空'))
      } else if (!reg.test(value)) {
        return callback(new Error('邮箱格式不正确'))
      } else {
        return callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (!value) {
        return callback(new Error('联系人电话不能为空'))
      } else if (!reg.test(value)) {
        return callback(new Error('联系人电话格式不正确'))
      } else {
        return callback()
      }
    }
    return {
      disabled: false,
      formData: {
        company: '',
        situation: '',
        question: '',
        name: '',
        connect: '',
        email: '',
        refer: this.$route.query.refer
      },
      customTitle: ['解决方案定制申请'],
      text: '',
      types: [
        {
          value: '企业内部教育培训'
        },
        {
          value: '企业外部营销培训'
        },
        {
          value: '线下业务在线化'
        },
        {
          value: '视频监控'
        },
        {
          value: '企业发布会活动'
        },
        {
          value: '企业名牌活动'
        },
        {
          value: '视频会议'
        },
        {
          value: '知识竞赛'
        }
      ],
      rules: {
        company: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' }
        ],
        situation: [
          { required: true, message: '应用场景不能为空', trigger: 'blur' }
        ],
        question: [
          { required: true, message: '需求描述不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '联系人姓名不能为空', trigger: 'blur' }
        ],
        connect: [{ validator: validatePhone, trigger: 'blur' }],
        email: [{ validator: validateEmail, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitApply () {
      const params = { ...this.formData }
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disabled = true
          this.$fetch('saveCustomMessage', params).then(() => {
            this.disabled = false
            this.$message({
              message: '提交成功',
              type: 'success'
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/varible.less';
h3 {
  margin: 0;
  padding: 0;
}
.custom {
  width: 960px;
  margin: 0 auto;
  background: @grayBcgColor;
  padding: 0px 0 50px 0;
  border-radius: 4px;
  .title {
    width: 960px;
    // margin-top: 76px;
  }
  .content {
    width: 100%;
    margin: 50px 0 0 0;
    padding: 34px 0 50px;
    box-shadow: @boxShadow;
    background: #fff;
    .form {
      margin: 0px auto;
      width: 400px;
      h3 {
        margin-bottom: 30px;
        color: #333;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
      }
      .icon {
        font-size: 20px;
        position: absolute;
        left: 10px;
      }
      .iconyouxiang {
        font-size: 14px;
      }
      .iconjingshi {
        color: #ff3939;
        font-size: 14px;
        position: absolute;
        right: -20px;
      }
      /deep/ .el-input {
        .el-input__inner {
          padding-left: 40px;
          background: #f9fbfe;
        }
      }
      /deep/ .el-textarea__inner {
        background: #f9fbfe;
      }
      /deep/ .el-select {
        width: 400px;
        .el-input__inner {
          padding-left: 15px;
        }
      }
    }
    .link-btn {
      line-height: 1;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 60px;
    }
  }
}
</style>
