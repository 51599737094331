<template>
  <div class="wrap c-width"
       :class="{ isDocTitle: isDocTitle }">
    <div v-for="(item, index) in titleArr"
         :key="index"
         class="title">
      {{ item }}
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    titleArr: {
      type: Array,
      default () {
        return []
      }
    },
    lineColor: {
      type: String,
      default: '#555'
    },
    isDocTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.isDocTitle {
    .title {
      margin-top: 0px;
      color: #fff;
    }
    .line {
      background: #fff;
    }
  }
  .title {
    margin-top: 40px;
    font-size: 34px;
    font-weight: 500;
    color: #555;
    &:nth-of-type(2) {
      margin-top: 0px;
    }
  }
  .line {
    height: 6px;
    width: 60px;
    margin: 22px 0 0px;
    border-radius: 4px;
    background: #555;
  }
}
</style>
